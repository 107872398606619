.custom-slider::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  background: #002fff;
  cursor: pointer;
}

.custom-slider:hover {
  opacity: 1;
}

.custom-slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: #002fff;
  cursor: pointer;
  border-radius: 50%;
}

.custom-slider {
  width: 100%;
  height: 30px;
  background: #ee0000;
  opacity: 1;
  outline: none;
  transition: 0.3s;
  -webkit-appearance: none;
}

.input-range__slider {
  background: #fcfcfc !important;
  border: 7px solid #3f9fed !important;
  margin-top: -1.2rem !important;
  width: 26.325px !important;
  height: 26.325px !important ;
}

.input-range__track {
  /* background: #b6e3f9 !important; */
  height: 10.879px !important;
}
.input-range__track--active {
  background: #b6e3f9 !important;
}

.txt-working-days-per-week {
  color: var(--neutral-900, #475160);
  /* font-family: Satoshi; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.wrap-range-working-days .input-range__label--min .input-range__label-container,
.wrap-range-working-days .input-range__label--max .input-range__label-container,
.wrap-range-working-days .input-range__label-container {
  display: none !important;
}
.wrap-range-select {
  padding: 0px 100px 0px 0px;
}
.range-select-val {
  position: absolute;
  right: 0;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: var(--neutral-900, #475160);
}
.wrap-range-working-days {
  position: relative;
}

.filter-h-r6r .css-1u9des2-indicatorSeparator {
  display: none;
}
.filter-h-r6r .css-t3ipsp-control {
  padding: 16px 32px;
  border-radius: 16px !important;
}

.wrap-title-route-name {
  min-height: 100px;
}
