.wrap-find-route {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.75);
  padding: 16px;
  z-index: 2;
  position: absolute;
  left: 40%;
  transform: translateX(-50%);
  bottom: 110px;
  z-index: 1;
  width: 80%;
}
.wrap-find-route .select-filter-items .select__control {
  border-radius: 8px;
  background: #fff;
  border: 0;
}
.wrap-find-route
  .select-filter-items
  .select__control
  .select__indicators
  .select__indicator-separator {
  display: none;
}
.wrap-btn-find-route button {
  padding: 15px 20px;
  border-radius: 8px;
  background: #3f9fed;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--neutral-0, #fff);
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  border: 0;
}
.wrap-filter-padding {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--neutral-600, #afbbca);
  background: #fff;
  padding: 9px;
}
.wrap-filter-padding img {
  object-fit: contain;
}
.wrap-filter-padding .select__control--is-focused {
  box-shadow: unset !important;
}

.wrap-filter-padding .select-filter-items .css-1nmdiq5-menu {
  z-index: 9999999;
}
