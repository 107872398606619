/* Styles for mobile devices */
@media only screen and (max-width: 600px) {
  .wrap-menu-responsive {
    display: flex !important;
  }
  .wrap-contact-menu {
    background-color: unset !important;
    padding: 12px 0px !important;
  }
  .mobile-menu-btn {
    margin-top: 25px;
  }
  header.style-1 .main-menu .menu-list {
    float: unset !important;
  }

  .wrap-intro {
    padding: 15px;
  }

  .wrap-section-efficiency {
    padding: 30px;
  }
  .wrap-section-efficiency .right-content {
    padding: unset;
  }
  .wrap-route-make {
    padding: 25px;
  }
  .wrap-range-select {
    padding: 15px;
  }
  .wrap-header .inner-banner {
    height: 200px !important;
    min-height: 200px !important;
  }
  .wrap-header .inner-banner h1 {
    font-size: 28px;
  }
  .wrap-header .inner-banner p {
    font-size: 28px !important;
  }
  .wrap-header .inner-banner p.first-title {
    margin-top: 45px !important;
  }
  .wrap-header .inner-banner h1.first-title {
    margin-top: 40px !important;
    margin-left: 30px !important;
  }
  .wrap-header .inner-banner p.first-title {
    margin-top: 20px !important;
  }
  .wrap-header .inner-banner p {
    font-size: 11px !important;
  }
  .swiper-slide {
    padding: 15px !important;
  }

  /* Footer */
  .wrap-header .inner-banner-2 {
    background-image: url('../images/banner/footer.svg') !important;
    min-height: 200px !important;
    height: 200px !important;
  }

  .wrap-header .inner-banner-2 img {
    margin-bottom: 0px;
    height: 28px;
  }
  .wrap-header .inner-banner-2 h1 {
    font-size: 22px;
  }

  .wrap-header .inner-banner-2 p.first-title {
    margin-top: 0;
  }
  .wrap-header .inner-banner-2 p.secund-title {
    margin-bottom: 10px;
  }
  .wrap-header .inner-banner-2 p {
    font-size: 11px;
  }
  .wrap-header .inner-banner-2 .wrap-btn-buy-route,
  .wrap-header .inner-banner-2 .wrap-btn-sell-route {
    padding: 8px !important;
    font-size: 11px;
  }

  .wrap-find-route {
    position: initial;
    transform: unset;
    width: unset;
    flex-direction: column;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .wrap-find-route .wrap-filter-padding {
    margin-bottom: 15px;
  }
  .wrap-find-route .w-40 {
    width: 100%;
  }
  .wrap-btn-find-route button {
    padding: 2px 12px;
    border-radius: 8px;
    background: #3f9fed;
    font-size: 16px;
    height: 45px;
  }
  .filter-mobile-responsive {
    display: block;
    padding: 8px;
    position: absolute;
    top: 16rem;
    left: 2rem;
    z-index: 9;
    width: 22rem;
  }
  .testimonial-section {
    margin-top: 150px;
  }
  .mobile-margin {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .filter-mobile-responsive {
    display: block;
    padding: 8px;
    position: absolute;
    top: 17rem;
    left: 2rem;
    z-index: 9;
    width: 20rem;
  }
  .wrap-find-route {
    border-radius: 8px;
    position: initial;
    transform: unset;
    width: unset;
    flex-direction: column;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .wrap-btn-find-route button {
    padding: 2px 12px;
    border-radius: 8px;
    background: #3f9fed;
    font-size: 14px;
    height: 45px;
  }
}
@media only screen and (max-width: 393px) {
  .filter-mobile-responsive {
    display: block;
    padding: 8px;
    position: absolute;
    top: 17rem;
    left: 1rem;
    z-index: 9;
    width: 22rem;
  }
}
@media only screen and (max-width: 360px) {
  .filter-mobile-responsive {
    left: 1.3rem;
    width: 20rem;
  }
}
