/* Styles for iPads */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .wrap-menu-responsive {
    display: flex !important;
    float: right;
  }
  .wrap-contact-menu {
    background-color: unset !important;
    padding: 12px 0px !important;
  }
  .mobile-menu-btn {
    margin-top: 25px;
  }
  header.style-1 .main-menu .menu-list {
    float: unset;
  }
  .wrap-topic .topic-name {
    padding: 10px;
    font-size: 12px !important;
  }

  .wrap-intro {
    padding: 15px !important;
  }

  .wrap-section-efficiency {
    padding: 30px !important;
  }
  .wrap-route-make {
    padding: 25px !important;
  }
  .wrap-range-select {
    padding: 15px !important;
  }

  .wrap-header .inner-banner p:first-child {
    margin-top: 45px !important;
  }
  .wrap-header .inner-banner {
    min-height: 400px !important;
  }
}

/* Styles for iPad Air */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .wrap-menu-responsive {
    display: flex !important;
    float: right !important;
  }
  .wrap-contact-menu {
    background-color: unset !important;
    padding: 12px 0px !important;
  }
  .mobile-menu-btn {
    margin-top: 25px !important;
  }
  header.style-1 .main-menu .menu-list {
    float: unset;
  }
  .wrap-topic .topic-name {
    padding: 10px !important;
    font-size: 12px !important;
  }

  .wrap-intro {
    padding: 15px !important;
  }

  .wrap-section-efficiency {
    padding: 30px !important;
  }
  .wrap-section-efficiency .right-content {
    padding: unset !important;
  }
  .wrap-route-make {
    padding: 25px !important;
  }
  .wrap-range-select {
    padding: 15px !important;
  }

  .wrap-header .inner-banner p.first-title {
    margin-top: 45px !important;
  }
  .wrap-header .inner-banner {
    min-height: 400px !important;
  }
  .header-logo img {
    min-width: 130px;
    max-width: 100%;
    height: 40px !important;
  }
  .testimonial-section {
    margin-top: 70px;
  }
  .wrap-btn-find-route button {
    padding: 15px 15px;
    border-radius: 8px;
    background: #3f9fed;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: var(--neutral-0, #fff);
    display: flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 0;
  }

  .wrap-find-route {
    position: initial;
    transform: unset;
    width: unset;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .wrap-find-route {
    position: initial;
    transform: unset;
    width: unset;
    background: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .filter-desktop-responsive {
    display: none;
  }
  .w-40 {
    width: 38%;
  }
}

/* filter pad air */
@media only screen and (max-width: 820px) {
  .filter-mobile-responsive {
    width: 43rem;
    padding: 8px;
    position: absolute;
    top: 29rem;
    left: 8%;
    /* right: 5%; */
    z-index: 9;
    display: block;
  }
}

/* pad mini */
@media only screen and (max-width: 768px) {
  .filter-mobile-responsive {
    width: 43rem;
    padding: 8px;
    position: absolute;
    top: 29rem;
    left: 5%;
    right: 5%;
    z-index: 9;
  }
}
