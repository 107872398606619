body {
  background-color: #f5f7f9;
}
footer {
  background-image: unset;
  background-color: #333e46;
}
.wrap-menu-responsive {
  display: none !important;
}
.eg-card {
  border: unset;
}
.auction-card1 .auction-content {
  padding: 30px 0px;
  padding-bottom: 0;
}
.footer-copy-right {
  margin-top: 1rem;
}
.header.style-1 .header-logo {
  background: unset !important;
}
.header.style-1 .header-logo img {
  height: 60px !important;
}
.preloader {
  background-color: #3f9fed;
}
.wrap-contact-menu {
  background-color: #3f9fed;
  padding: 15px 25px !important;
  border-radius: 5px;
  color: #fff !important;
}
.hero-style-one .slider-bg-1::before {
  background-image: url('../images/banner/home.png') !important;
}
.hero-style-one .slider-bg-1::before {
  background-size: cover;
}
.hero-style-one .banner1-content {
  text-align: left !important;
  max-width: unset !important;
}
.hero-style-one .banner1-content p {
  margin-left: initial !important;
}

/* Banner 2 */
.hero-style-one .slider-bg-2 {
  padding: 50px 0px;
}
.hero-style-one .slider-bg-2::before {
  background-image: url('../images/banner/footer.svg') !important;
}
.hero-style-one .slider-bg-2::before {
  background-size: cover;
}

.hero-style-one .banner2-content {
  width: 100%;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.hero-style-one .banner2-content span {
  font-size: 1.25rem;
  font-weight: 400;
  color: #eeeeee;
  margin-bottom: 10px;
  display: block;
}
.hero-style-one .banner2-content h1,
.hero-style-one .banner2-content h2 {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-one .banner2-content h1,
  .hero-style-one .banner2-content h2 {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .hero-style-one .banner2-content h1,
  .hero-style-one .banner2-content h2 {
    font-size: 50px;
    padding: 20px;
  }
  .hero-style-one .banner2-content p {
    padding: 20px;
  }
}
.hero-style-one .banner2-content p {
  font-size: 16px;
  font-weight: 400;
  color: #eeeeee;
  margin-bottom: 55px;
  width: 85%;
  margin-left: initial;
  margin-right: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-one .banner2-content p {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .hero-style-one .banner2-content p {
    width: 98%;
  }
}

.wrap-btn-buy-route {
  background: #3f9fed !important;
  color: #ffffff;
  text-transform: none;
}
.wrap-btn-sell-route {
  background: transparent !important;
  border: solid 1px #f2fbfe;
  color: #ffffff;
  text-transform: none;
}

.btn-find-more-route {
  background-color: #3f9fed;
  border-radius: 15px;
}

.btn-find-more-route img {
  height: 55px;
}

.btn-find-more-route h4,
.btn-find-more-route a {
  color: #fff !important;
}

.btn-find-more-route .wrap-btn-find-more-route {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto 0;
  height: 100%;
  text-align: center;
}

.btn-find-more-route .auction-content,
.btn-find-more-route .wrap-btn-find-more-route {
  border: unset;
  background: unset;
}
.wrap-btn-get-access-route {
  padding: 5px;
  color: #fff;
  background-color: #3f9fed;
}
.wrap-btn-get-access-route .title {
  color: #475160;
}
.wrap-btn-get-access-route .price {
  color: #333e46;
}
.wrap-btn-get-access-route .state {
  color: #68778d;
}
.wrap-btn-access .btn-access-route,
.wrap-btn-access .btn-access-route::before,
.wrap-btn-access .btn-access-route::after {
  width: 100% !important;
  color: #fff !important;
  padding: 14px 24px !important;
  border-radius: 10px!important;
}
.wrap-btn-access .btn-access-route::before,
.wrap-btn-access .btn-access-route::after {
  background: #3f9fed !important;
  color: #fff !important;
}

.wrap-intro {
  background-color: #f2fbfe;
  border-radius: 36px;
  padding: 90px;
}
.wrap-intro .section-title1 h2 {
  color: #333e46;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.wrap-intro .section-title1 p {
  color: #333e46;
  text-align: center;
  font-size: 18px;
  font-style: normal;
}
.wrap-intro .single-feature {
  background: unset !important;
  border: unset;
  min-height: 320px;
  text-align: left;
  height: 400px;
}
.wrap-intro .single-feature:hover,
.wrap-intro .single-feature:active,
.wrap-intro .single-feature:focus {
  background: unset !important;
  border: unset !important;
  box-shadow: unset !important;
}
.wrap-intro .single-feature .link-to {
  color: #3f9fed;
}
.wrap-intro .single-feature p {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.wrap-intro .single-feature a {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #3f9fed;
}

.wrap-right-route-make {
  background-color: #f2fbfe;
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 10px;
}

.wrap-right-route-make h4 {
  color: #68778d;
  font-size: 16px;
}
.wrap-right-route-make h1 {
  color: #3f9fed;
}
.wrap-right-route-make hr {
  color: #cceefc;
  width: 50%;
}
.wrap-right-route-make .wrap-week-sale {
  text-align: center;
}
.wrap-right-route-make .btn-buy-route {
  width: 100%;
  text-transform: none!important;
}
.wrap-right-route-make .wrap-expected p {
  color: #68778d;
  font-size: 12px;
}
.wrap-right-route-make .wrap-expected h5 {
  color: #475160;
  font-size: 16px;
}

p.txt-route-mark-cal {
  color: #68778d;
  font-size: 14px;
}
.wrap-route-make {
  background-color: #fff;
  border-radius: 36px;
  padding: 100px 80px 120px 80px;
}
.wrap-route-make .wrap-route-make-cs {
  background-color: #fff;
  border-radius: 36px;
  padding: 0 !important;
}
.wrap-section-efficiency {
  background-color: #fff;
}
/* .wrap-section-efficiency .left-content { */
/* padding: 150px 150px 0 0; */
/* } */
.wrap-section-efficiency .right-content {
  padding: 50px 90px;
}
.wrap-section-efficiency p {
  font-size: 18px;
}
.wr .wrap-section-efficiency .right-content img {
  width: 100%;
}
.wrap-section-footer-banner {
  background-color: #fff;
  border-radius: 36px;
}

.swiper-curve {
  border-radius: 30px;
}
footer .footer-top {
  padding: 40px 0 !important;
  /* background-color: #fff; */
  background-color: #333e46;
}

.select-filter-items-familiar .select__control {
  padding: 10px 24px;
  border: 0;
  border-radius: 16px;
  background: var(--neutral-100, #f9fafb);
}
.select-filter-items-familiar .select__control .select__indicator-separator {
  display: none !important;
}
.w-40 {
  width: 40%;
}
.w-80 {
  width: 80%;
}

/* Route */
.title-route-name {
  color: #475160 !important;
  line-height: 20px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.title-route-name p,
.title-route-price {
  margin-bottom: 5px !important;
}

.title-route-state {
  color: #68778d !important;
  line-height: 20px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.wrap-txt-offer .wrap-txt-offer-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.wrap-filter-pending .css-1jqq78o-placeholder {
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  color: #475160 !important;
}
.bg-footer-content {
  background: #f9fafb;
}

header.style-1 .main-menu .mobile-logo-wrap {
  background: unset !important;
}

.wrap-filter-header-bg {
  display: inline-flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.75);
}

.bg-banner {
  /* display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background: url('../images/banner/home.png') !important;
  background-size: cover;
  background-position: center center;
  padding: 15px; */
  background: url('../images/banner/home.png') !important;
  min-height: 530px;
  background-size: cover !important;
  background-repeat: repeat-y;
}
.bg-banner .inner-form {
  background: rgba(0, 0, 0, 0.5);
  padding: 50px 47px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.bg-banner .inner-form .input-field.first-wrap {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.bg-banner .inner-form .input-field {
  margin-right: 30px;
  height: 68px;
}
.bg-banner .inner-form .input-field.second-wrap {
  width: 32.77%;
  min-width: 180px;
}
.bg-banner .inner-form .input-field.third-wrap {
  width: 180px;
  margin-right: 0;
}
.bg-banner .inner-form .input-field.third-wrap .btn-search {
  height: 100%;
  width: 100%;
  background: #4272d7;
  white-space: nowrap;
  border-radius: 0.5px;
  font-size: 20px;
  color: #fff;
  transition: all 0.2s ease-out, color 0.2s ease-out;
  border: 0;
  cursor: pointer;
}

header.style-1 .header-logo {
  float: left !important;
}
header.style-1 .main-menu .menu-list {
  float: right !important;
}
.wrap-home-content {
  background-color: #fff;
}
.faq-wrap .accordion-button::after {
  color: #68778d !important;
}
.faq-wrap .accordion-button:not(.collapsed)::after {
  background: unset !important;
}
.faq-wrap .accordion-body {
  background: unset !important;
}
table.wrap-skeleton {
  margin-top: 35px !important;
}
table.wrap-skeleton tr {
  border-bottom: unset !important;
}

/* Header */
.wrap-header .inner-banner {
  background-image: url('../images/banner/home.png') !important;
  min-height: 550px !important;
  border-radius: 35px !important;
  background-size: cover !important;
  align-items: unset !important;
}
.wrap-header .inner-banner .wrap-find-route {
  margin-top: 50px;
  margin-left: 0px;
  margin-right: 90px;
}
.wrap-header .inner-banner h1 {
  color: #fff;
  margin-left: 30px;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: capitalize;
}

.wrap-header .inner-banner h1.first-title {
  margin-top: 80px;
  margin-left: 30px;
}

.wrap-header .inner-banner p.first-title {
  margin-top: 15px;
}

.wrap-header .inner-banner p {
  color: #eee;
  font-size: 18px;
  font-style: normal;
  margin-bottom: 0;
  margin-left: 30px;
  opacity: 0.7;
}

/* Footer */

.wrap-header .inner-banner-2 .container {
  margin-left: 30px !important;
}
.wrap-header .inner-banner-2 {
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-image: url('../images/banner/footer.svg') !important;
  min-height: 400px !important;
  border-radius: 35px !important;
  background-size: cover !important;
  align-items: center !important;
}

.wrap-header .inner-banner-2 img {
  margin-bottom: 40px;
}
.wrap-header .inner-banner-2 h1 {
  color: #fff;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: capitalize;
}

.wrap-header .inner-banner-2 p.first-title {
  margin-top: 25px;
}
.wrap-header .inner-banner-2 p.secund-title {
  margin-bottom: 25px;
}
.wrap-header .inner-banner-2 p {
  margin-bottom: 0px;
  color: #f9fafb;
  font-size: 16px;
  font-style: normal;
  margin-bottom: 0;
  opacity: 0.7;
}
.filter-mobile-responsive {
  display: none;
}
.single-feature img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.content {
  min-height: 200px;
}
.find-more-view {
  border-radius: 8px;
  background: var(--blue-900, #3f9fed);
  cursor: pointer;
}
.find-more-view .auction-card1 .auction-content {
  padding: 0 !important;
}
.find-more-view .auction-card1 {
  background: var(--blue-900, #3f9fed);
}
.find-more-view .auction-card1 .auction-content h4 {
  color: var(--neutral-0, #fff);
  font-family: Satoshi;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  margin-top: 16px;
}
.toggle-wrap {
  padding: 0 !important;
  height: 59px;
  width: 59px;
  border-radius: 10px;
}
.toggle-wrap img {
  width: inherit;
  height: inherit;
  object-fit: contain;
  border-radius: 10px;
  border: 0.538px solid var(--neutral-400, #e2e5e9);
}
.wrap-route-make .css-b62m3t-container .css-13cymwt-control {
  border: 0;
  border-radius: 16px;
  background: var(--neutral-100, #f9fafb);
  padding: 16px 32px;
}
.wrap-route-make
  .css-b62m3t-container
  .css-13cymwt-control
  .css-1u9des2-indicatorSeparator {
  display: none;
}
@media (min-width: 992px) {
  .col-lg-c-2 {
    width: 20%; /* Set the width to 20% of the container's width */
  }
}


.wrap-map-master-route,
.wrap-map-similar-route {
  border-radius: 10px!important;
}

.error-input {
  outline: none!important; /* Remove the outline when the input is focused */
  border: 1px solid #D91125!important;
}